import coverImage from './Cover_Image.jpg';
import QRInvite from './QR_Invite.png';
import title from './Title.png';

const imagesHome = {
    coverImage,
    QRInvite,
    title
}

export default imagesHome;